import React, {Fragment} from 'react';
import HeroHeader from "../components/HeroHeader";
import Epgrid from "../components/Epgrid";
import EPGrid2 from "../components/EPGrid2";
import EPOthers from "../components/EPOthers";
import Epoverview from "../components/Epoverview";
import CallToAction from "../components/CallToAction";


const Index = () => {
    return (
        <Fragment>
            <HeroHeader />
			<Epgrid />
			<EPGrid2 />
			<EPOthers />
			<Epoverview />
			<CallToAction />
        </Fragment>
    );
}

export default Index;
