import React from "react";
import { Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import Epcart from "./components/Epcart";
import Epcheckout from "./components/Epcheckout";
import Faq from "./components/Faq";
import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
import ContactUs from "./components/ContactUs";

const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Index />} />
            <Route exact path="/cart" element={<Epcart />} />
            <Route exact path="/checkout" element={<Epcheckout />} />
            <Route exact path="/signin" element={<SignIn />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
        </Routes>
    );
};

export default AppRoutes;

