import "./style.css";
import AppRoutes from './AppRoutes.js';
import Navigation from "./components/Navigation.js";

const App = () => {
    return (
        <div className="App">
           <Navigation />
           <AppRoutes />
        </div>
    );
};

export default App;
