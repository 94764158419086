import React from "react";

const CallToAction = () => {
	return (
		<section className="ezy__cta7_NfGkzwsO py-14 md:py-24 bg-white dark:bg-[#0b1727] text-white">
			<div className="container px-4">
				<div className="bg-[#303446] rounded-3xl overflow-hidden md:py-6">
					<div className="grid grid-cols-12">
						<div className="col-span-12 lg:col-span-8 lg:col-start-3 lg:px-12">
							<div className="py-12 px-6 sm:px-12 text-center">
								<h2 className="text-2xl md:text-[40px] leading-none font-bold mb-6">
									Join with us for more information
								</h2>

								<div className="grid grid-cols-12 justify-center">
									<div className="col-span-12 sm:col-span-8 sm:col-start-3">
										<p className="text-lg opacity-75 mb-12">
											It’s easier to reach your savings goals when you have the
											right savings account.
										</p>
									</div>
								</div>

								<div>
									<button className="text-white hover:text-black border px-7 py-3 rounded hover:bg-white sm:mr-2 transition mt-3">
										Learn More
									</button>
									<button className="text-black border px-7 py-3 rounded bg-white hover:bg-opacity-90 transition mt-3">
										Quote Now
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CallToAction;

