import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const routes = [
	{ name: "Home", href: "/", isActive: true },
	{ name: "Cart", href: "/cart", isActive: false },
	{ name: "Checkout", href: "/checkout", isActive: false },
	{ name: "SignIn", href: "/signin", isActive: false },
	{ name: "SignUp", href: "/signUp", isActive: false },
	{ name: "Contact us", href: "/contact-us", isActive: false },
];


const NavMenu = ({ routes }) => (
	<ul className="flex">
		{routes.map((route, i) => (
			<li key={i}>
				<NavLink
					className={`px-4 ${
						route.isActive ? "opacity-100" : "opacity-50 hover:opacity-100"
					}`}
					to={route.href}
				>
					{route.name}
				</NavLink>
			</li>
		))}
	</ul>
);

NavMenu.propTypes = {
	routes: PropTypes.array.isRequired,
};

const Navigation = () => {
	return (
		<div className="ezy__nav4_xCm9B3pz py-6 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white relative">
			<nav>
				<div className="container px-4">
					<div className="flex items-center justify-between">
						<a className="font-black text-3xl min-w-[33%]" href="/">
							{" "}
							E Store{" "}
						</a>
						<button
							className="block lg:hidden cursor-pointer h-10 z-20"
							type="button"
							id="hamburger"
						>
							<div className="h-0.5 w-7 bg-black dark:bg-white -translate-y-2"></div>
							<div className="h-0.5 w-7 bg-black dark:bg-white"></div>
							<div className="h-0.5 w-7 bg-black dark:bg-white translate-y-2"></div>
						</button>
						<div
							className="flex flex-col lg:flex-row justify-center lg:justify-between items-center text-3xl gap-6 lg:text-base lg:gap-2 absolute h-screen w-screen top-0 left-full lg:left-0 lg:relative lg:h-auto lg:w-auto bg-white dark:bg-[#0b1727] lg:bg-transparent grow"
							id="navbar"
						>
							<NavMenu routes={routes} />

						</div>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default Navigation;

